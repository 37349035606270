<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {timeUtil} from "@/helpers/time-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {imageHelper} from "../../../../helpers/image-helper";
import ecatCropper from "@/components/ecat-cropper.vue";

export default {
  components: {
    Layout,
    PageHeader,
    ecatCropper
  },

  computed: {
    timeUtil() {
      return timeUtil
    },

    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        elements: null
      },

      product: null,
      taxes: null,

      modals: {
        editVariation: {
          visible: false,
        },

        editName: {
          visible: false
        },

        editDescription: {
          visible: false
        }
      },

      editedProductVariation: {
        taxId: "",
        currency: "",
        subscriptionTimeUnit: "",
        subscriptionTime: 0,
        title: "",
        description: "",
        price: 0,
        value: 0
      },

      editedProduct: null,

      productTypes: null,
      currencies: null,
      units: null,
    };
  },

  validations: {
    editedProduct: {
      name: {required},
      description: {required}
    },

    editedProductVariation: {
      title: {required},
      description: {required},
      price: {required},
      currency: {required},
      taxId: {required},
      subscriptionTime: {required},
      subscriptionTimeUnit: {required},
      value: {required}
    }
  },

  methods: {

    async successCropImage({ extension, file }) {
      this.pictureFile = file

      try {
        await this.upload(file, extension)
      } catch (ignored) {
        // ignored
      }
    },

    async upload(file, extension) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('extension', extension)

      try {
        const { data } = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        const imageUrl = data.url;
        await this.updateProduct(imageUrl)
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    async updateProduct(imageUrl) {
      const obj = Object.assign({}, this.product)
      obj.imageUrl = imageUrl

      const json = JSON.stringify(obj)
      try {
        const { data } = await axios.put(`/marketplace/product`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.product = data
        await Swal.fire("Sukces!", 'Pomyślnie zaktualizowano zdjęcie', "success");
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    sendProductImage(input) {
      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {dataUrl} = result
        this.$refs.ecatCropper.openModal(dataUrl, "rectangle-stencil", 800/480)
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'productsTable')
    },

    openEditNameModal() {
      this.editedProduct = Object.assign({}, this.product)
      this.modals.editName.visible = true;
    },

    hideEditNameModal() {
      this.modals.editName.visible = false;
      this.editedProduct = null;
    },

    openEditDescriptionModal() {
      this.editedProduct = Object.assign({}, this.product)
      this.modals.editDescription.visible = true;
    },

    hideEditDescriptionModal() {
      this.modals.editDescription.visible = false;
      this.editedProduct = null;
    },

    openVariationModal(productVariation = null) {
      this.modals.editVariation.visible = true;

      if (productVariation) {
        this.editedProductVariation = Object.assign({}, productVariation);
      }
    },

    hideVariationModal() {
      this.modals.editVariation.visible = false;
      this.editedProductVariation = {
        taxId: "",
        currency: "",
        subscriptionTimeUnit: "",
        subscriptionTime: 0,
        title: "",
        description: "",
        price: 0,
        value: 0
      }
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.marketplace.product-details.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "price", label: 'Cena netto'},
        {
          key: "discount", label: 'Rabat procentowy', formatter: value => {
            if (!value) {
              return "-"
            }

            return value + "%"
          }
        },
        {key: "tax", label: 'Podatek'},
        // {key: "status", label: 'Subskrybcja'},
        {key: "subscriptionTime", label: 'Liczba aktywności'},
        {
          key: "subscriptionTimeUnit", label: 'Jednostka aktywności', formatter: value => {
            return timeUtil.getHumanDay(value)
          }
        },
        {key: "provision1", label: 'Prowizja dla partnerów ECAT'},
        {key: "provision2", label: 'Prowizja dla zakwalifikowanych partnerów ECAT'},
        {key: "value", label: 'Wartość'},
        {key: "action", label: 'Akcje'}
      ]
    },

    async loadProductVariations() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/marketplace/product/variation/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "type": this.product.type,
          "page": page,
          "size": this.table.perPage
        }
      });

      this.table.elements = result.data.marketplaceProductVariations;
      this.table.totalRows = result.data.count;
      this.table.rows = result.data.count;
      await this.loadTaxes()

      if (this.mounted) {
        this.$refs.productsTable.refresh()
      }

      return this.table.elements;
    },

    async loadTaxes() {
      if (this.table.elements.length === 0) {
        return;
      }

      const ids = [];
      for (let productVariation of this.table.elements) {
        ids.push(productVariation.taxId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/tax/list-by-ids`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.taxes = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    async loadProductTypes() {
      try {
        const result = await axios.get(`/marketplace/product/types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.productTypes = result.data
      } catch (error) {
        console.log(error)
      }
    },

    async loadCurrencies() {
      try {
        const result = await axios.get(`/marketplace/product/currencies`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.currencies = result.data
      } catch (error) {
        console.log(error)
      }
    },

    async loadUnits() {
      try {
        const result = await axios.get(`/marketplace/product/units`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.units = result.data
      } catch (error) {
        console.log(error)
      }
    },

    async loadProduct() {
      const id = this.$route.params.id;
      if (!id) {
        return;
      }

      const result = await axios.get(`/marketplace/product/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.product = result.data
    },

    getTaxRate(taxId) {
      if (!this.taxes) {
        return ""
      }

      const tax = this.taxes.get(taxId);
      if (!tax) {
        return "";
      }

      return tax.rate;
    },

    createOrEditProductVariation() {
      if (!this.editedProductVariation.id) {
        this.editedProductVariation.marketplaceProductId = this.product.id
      }

      const json = JSON.stringify(this.editedProductVariation)

      axios.put(`/marketplace/product/variation`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideVariationModal()
        Swal.fire("Sukces!", this.editedProductVariation.id ? "Pomyślnie utworzono nową wariację" : "Pomyślnie zaktualizowano wariację!", "success")
            .then(() => {
              setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
            });
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    editProduct() {
      const json = JSON.stringify(this.editedProduct)
      axios.put(`/marketplace/product`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        this.hideEditNameModal()
        this.product = data
        Swal.fire("Sukces!", 'Pomyślnie edytowano nazwe produktu', "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    changeRemovedStatus(item) {
      const duplicateObj = Object.assign({}, item)
      duplicateObj.removed = !duplicateObj.removed
      const json = JSON.stringify(duplicateObj)

      axios.put(`/marketplace/product/variation`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        item.removed = duplicateObj.removed
        Swal.fire("Sukces!", item.removed ? 'Pomyślnie usunięto wariacje produktu' : 'Pomyślnie przywrócono wariacje produktu', "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    }

  },

  async created() {
    await this.loadProduct()
    await this.loadProductVariations()
    await this.loadProductTypes()
    await this.loadCurrencies()
    await this.loadUnits()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('admin.marketplace.product-details.title')" />
    <ecat-cropper ref="ecatCropper" @success="successCropImage" />

    <template v-if="product">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4>{{ product.name }}
                <button class="btn btn-primary btn-sm" @click="openEditNameModal"><i class="mdi mdi-pencil"></i></button>
              </h4>

              <div class="row py-3">
                <div class="col-12 col-lg-4">
                  <img :src="product.imageUrl" alt="default image" class="rounded img-fluid" style="width: 400px; height: 225px">

                  <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="send-picture" @input="sendProductImage" hidden/>
                  <label for="send-picture" class="btn btn-primary btn-sm mx-2"><i class="mdi mdi-pencil"></i></label>
                </div>

                <div class="col-12 col-lg-8">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                      <tr>
                        <th>Właściwość</th>
                        <td><b>Wartość</b></td>
                      </tr>

                      <tr>
                        <th>Id</th>
                        <td>{{ product.id }}</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td><span :class="product.removed ? 'badge-soft-danger' : 'badge-soft-success'"
                                  class="badge">{{ product.removed ? 'Usunięty' : 'Aktywny' }}</span>
                        </td>
                      </tr>

                      <tr>
                        <th>Typ</th>
                        <td>{{ product.type.replaceAll("_", " ").toLowerCase() }}</td>
                      </tr>

                      <tr>
                        <th>Utworzono</th>
                        <td>{{ dateUtil.asDateTime(product.createdAt) }}</td>
                      </tr>

                      <tr>
                        <th>Zaktualizowano</th>
                        <td>{{ product.updatedAt ? dateUtil.asDateTime(product.updatedAt) : "-" }}</td>
                      </tr>

                      <tr>
                        <th>Usunięto</th>
                        <td>{{ product.removedAt ? dateUtil.asDateTime(product.removedAt) : "-" }}</td>
                      </tr>

                      <tr>
                        <th>Opis
                          <button class="btn btn-primary btn-sm" @click="openEditDescriptionModal"><i
                              class="mdi mdi-pencil"></i></button>
                        </th>
                        <td>{{ product.description }}</td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-button variant="primary" @click="openVariationModal">Dodaj wariację produktu</b-button>

              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="table.perPage" :options="table.pageOptions" size="sm"></b-form-select>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="table.filter"
                          class="form-control form-control-sm ml-2"
                          type="search"
                          @input="setToFirstPageAndRefresh" @keyup.enter="setToFirstPageAndRefresh"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table
                    ref="productsTable"
                    :current-page="table.currentPage"

                    :empty-filtered-text="$t('message.no-records')"
                    :empty-text="$t('message.no-elements')"
                    :fields="getFields()"
                    :filter-included-fields="table.filterOn"

                    :items="loadProductVariations"
                    :per-page="table.perPage"
                    :show-empty="true"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    responsive="sm" @filtered="elements => paginationHelper.onFiltered(table, elements)">
                  <template v-slot:cell(price)="{ item }">
                    <span>{{ item.price.toFixed(2) }} {{ item.currency }}</span>
                  </template>

                  <template v-slot:cell(tax)="{ item }">
                    <span>{{ getTaxRate(item.taxId) }}%</span>
                  </template>

                  <template v-slot:cell(action)="{ item }">
                    <a class="clickable-element mr-3 text-primary" @click="openVariationModal(item)"><i
                        class="mdi mdi-pencil font-size-18"></i></a>
                    <b-button :variant="item.removed ? 'success' : 'danger'" @click="changeRemovedStatus(item)">
                      {{ item.removed ? 'Przywróć' : 'Usuń' }}
                    </b-button>
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{
                        $t('table.entries-footer', {
                          'amount': paginationHelper.getElementsAmount(table),
                          'elements': paginationHelper.getElementsCount(table),
                          'all': table.totalRows
                        })
                      }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :per-page="table.perPage"
                                    :total-rows="table.rows"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

    <b-modal
        id="modal-1"
        v-model="modals.editVariation.visible"
        :title="editedProductVariation.id ? 'Edytowanie wariacji produktu' : 'Tworzenie nowej wariacji produktu'"
        hide-footer
        title-class="font-18"
        @esc="hideVariationModal"
        @hide="hideVariationModal">

      <div class="form-group">
        <label>Tytuł</label>
        <input v-model="editedProductVariation.title" :class="{ 'is-invalid': $v.editedProductVariation.title.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.editedProductVariation.title.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Opis</label>
        <textarea v-model="editedProductVariation.description" :class="{ 'is-invalid': $v.editedProductVariation.description.$error }" class="form-control"
                  type="text"/>
        <div v-if="!$v.editedProductVariation.description.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Cena netto</label>
        <input v-model.number="editedProductVariation.price" :class="{ 'is-invalid': $v.editedProductVariation.price.$error }" class="form-control"
               type="number"/>
        <div v-if="!$v.editedProductVariation.price.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Rabat procentowy</label>
        <input v-model.number="editedProductVariation.discount" class="form-control" type="number"/>
      </div>

      <div class="form-group">
        <label>Waluta</label>
        <vue-multiselect v-model="editedProductVariation.currency"
                         :class="{ 'is-invalid': $v.editedProductVariation.currency.$error }"
                         :options="currencies ? currencies : []"
                         :show-labels="false"
                         placeholder="Wybierz walutę">
        </vue-multiselect>
        <div v-if="!$v.editedProductVariation.currency.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <ecat-multiselect :class="{ 'is-invalid': $v.editedProductVariation.taxId.$error }"
                          :custom-label="value => value.rate + '%'"
                          :set-value="editedProductVariation.taxId"
                          :show-labels="false"
                          label="Podatek"
                          load-url="/tax/list/pagination"
                          param="name"
                          placeholder="Wybierz stawke VAT"
                          query-url="/tax/by-name"
                          save-id="id"
                          @change="value => editedProductVariation.taxId = value">
        </ecat-multiselect>

        <div v-if="!$v.editedProductVariation.taxId.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Liczba aktywności</label>
        <input v-model.number="editedProductVariation.subscriptionTime" :class="{ 'is-invalid': $v.editedProductVariation.subscriptionTime.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.editedProductVariation.subscriptionTime.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Jednostka aktywności</label>
        <vue-multiselect v-model="editedProductVariation.subscriptionTimeUnit"
                         :class="{ 'is-invalid': $v.editedProductVariation.subscriptionTimeUnit.$error }"
                         :custom-label="value => timeUtil.getHumanDay(value)"
                         :options="units ? units : []"
                         :show-labels="false"
                         placeholder="Wybierz jednostkę aktywności">
        </vue-multiselect>
        <div v-if="!$v.editedProductVariation.subscriptionTimeUnit.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Prowizja dla partnerów ECAT</label>
        <input v-model.number="editedProductVariation.provision1" class="form-control" type="text"/>
      </div>

      <div class="form-group">
        <label>Prowizja dla zakwalifikowanych partnerów ECAT</label>
        <input v-model.number="editedProductVariation.provision2" class="form-control" type="text"/>
      </div>

      <div class="form-group">
        <label>Wartość</label>
        <input v-model.number="editedProductVariation.value" :class="{ 'is-invalid': $v.editedProductVariation.value.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.editedProductVariation.value.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <!-- Rabat procentowy -->

      <div class="text-right">
        <b-button variant="success" @click="createOrEditProductVariation">
          {{ editedProductVariation.id ? 'Edytuj' : 'Utwórz' }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideVariationModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editedProduct"
        id="modal-2"
        v-model="modals.editName.visible"
        hide-footer
        title="Edytowanie nazwy produktu"
        title-class="font-18"
        @esc="hideEditNameModal"
        @hide="hideEditNameModal">

      <div class="form-group">
        <label>Nazwa</label>
        <input v-model="editedProduct.name" :class="{ 'is-invalid': $v.editedProduct.name.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.editedProduct.name.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="text-right">
        <b-button variant="success" @click="editProduct">Edytuj</b-button>
        <b-button class="ml-1" variant="danger" @click="hideEditNameModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editedProduct"
        id="modal-2"
        v-model="modals.editDescription.visible"
        hide-footer
        title="Edytowanie opisu produktu"
        title-class="font-18"
        @esc="hideEditDescriptionModal"
        @hide="hideEditDescriptionModal">

      <div class="form-group">
        <label>Opis</label>
        <textarea v-model="editedProduct.description" :class="{ 'is-invalid': $v.editedProduct.description.$error }" class="form-control"
                  type="text"/>
        <div v-if="!$v.editedProduct.description.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="text-right">
        <b-button variant="success" @click="editProduct">Edytuj</b-button>
        <b-button class="ml-1" variant="danger" @click="hideEditDescriptionModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>